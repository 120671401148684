import * as React from 'react'
import classNames from 'classnames'
import { Button, IButtonProps } from '@aurecon-creative-technologies/styleguide'

const DEFAULT_MODAL_PADDING: IModalPadding = {
  top: '24px',
  right: '24px',
  bottom: '24px',
  left: '24px',
}

export interface IModalPadding {
  top?: string
  right?: string
  bottom?: string
  left?: string
}

export interface IModalProps {
  children: React.ReactNode
  /** Show or Hide Modal */
  isShowing: boolean

  /** Show or Hide Close icon button
   * @default true
   */
  isCloseButton?: boolean

  /** Show or Hide Overlay Container
   * @default true
   */
  isOverlay?: boolean

  /** Allow/Disable onClose event for overlay
   * @default true
   */
  shouldOverlayClose?: boolean

  /** Configure modal inner padding
   * @default DEFAULT_MODAL_PADDING
   */
  modalPadding?: IModalPadding

  /** Action buttons for Modal
   * @default undefined
   */
  actions?: IButtonProps[]

  /** Size of the modal */
  size?: 'small' | 'medium' | 'large'

  /** Custom css class for themeing */
  cssClass?: string

  /** Method for hide modal */
  onClose: () => void
}

export interface IUseModal {
  /** Show or Hide Modal */
  isShowing: boolean

  /** Method for Show/Hide modal */
  toggleModal: () => void
}

/**
 * ###Usage:
 *
 * The Dialogue modal is used to provide important information to the user at a specific point in time, without leaving the current page.  User action is required to close/dismiss the modal.
 *
 * ###Guidelines:
 * The Dialogue modal should:
 *
 * -  Be appropriately interruptive; they should appear in context and in the right moment.
 * -  Contain a signle message with relevant user call-to-action. The message should be succinct with clear decision making calls-to-action (buttons).
 * -  Be positioned at the centre of the user’s view port.
 */
export const Modal: React.FC<IModalProps> = (props) => {
  const { isShowing, isCloseButton, isOverlay, shouldOverlayClose, modalPadding, actions, children, onClose } = props

  if (!isShowing) return null

  const { top, right, bottom, left } = {
    ...DEFAULT_MODAL_PADDING,
    ...modalPadding,
  }
  const modalStyles = {
    padding: `${top} ${right} ${bottom} ${left}`,
  }

  const _handleKeyDownHide = (e: React.KeyboardEvent<HTMLElement>): void => {
    if (e.key === 'Enter') {
      onClose()
    }
  }

  const modalClass = classNames({
    ['modal-wrapper']: true,
    ['is-small']: props.size === 'small',
    ['is-medium']: !props.size || props.size === 'medium',
    ['is-large']: props.size === 'large',
    [`${props.cssClass}`]: props.cssClass,
  })

  return (
    <div className={modalClass}>
      {isOverlay && <div className='modal-overlay' onClick={() => shouldOverlayClose && onClose()}></div>}
      <div className='modal' style={modalStyles}>
        {isCloseButton && (
          <i
            className='material-icons modal-close-icon'
            tabIndex={1}
            onClick={onClose}
            onKeyDown={(e) => _handleKeyDownHide(e)}
          >
            close
          </i>
        )}
        {children}
        {actions?.length ? (
          <div className='modal-actions'>
            {actions.map((action) => (
              <Button key={action.label} tabIndex={1} {...action} />
            ))}
          </div>
        ) : null}
      </div>
    </div>
  )
}

Modal.defaultProps = {
  modalPadding: DEFAULT_MODAL_PADDING,
  isCloseButton: true,
  isOverlay: true,
  shouldOverlayClose: true,
}

export const useModal = (bool = false): IUseModal => {
  const [isShowing, setIsShowing] = React.useState(bool)

  const toggleModal = () => {
    setIsShowing(!isShowing)
  }

  return {
    isShowing,
    toggleModal,
  }
}
