import { selector, atom } from 'recoil'
import { getAppVersion } from '../api/AppService'

export const AppVersion = selector({
  key: 'version',
  get: async () => {
    const response = await getAppVersion()
    return response.data?.version
  },
})

export const TermsModal = atom({
  key: 'terms_modal',
  default: false,
})
