import { IResponse } from '../models/api/IResponse'

export interface IAppVersion {
  version: string
}

const FAIL_VERSION_RESPONSE = { success: false, data: { version: '0.0.0' } }

export const getAppVersion = async (): Promise<IResponse<IAppVersion>> => {
  try {
    const response = await fetch('/app/version')
    if (response.ok) return await response.json()
    else return FAIL_VERSION_RESPONSE
  } catch {
    return FAIL_VERSION_RESPONSE
  }
}
