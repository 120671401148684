import { FC, useMemo } from 'react'
import { useRecoilValueLoadable } from 'recoil'

import { AppVersion } from '../stores/AppStore'
import AureconLogo from '../../src/assets/aurecon_logo_black.svg'

import Style from '../styles/Footer.module.sass'

const Footer: FC = () => {
  const appVersion = useRecoilValueLoadable(AppVersion)

  const version = useMemo(() => {
    if (appVersion.state !== 'hasValue') return '0.0.0'

    return appVersion.contents
  }, [appVersion])

  const footerYear = new Date().getFullYear()
  return (
    <div className={Style.footerWrapper}>
      <img src={AureconLogo} alt='Aurecon Logo' />{' '}
      <p>
        <a href='/#/termsandconditions' target='_blank' rel='noreferrer'>
          Terms & Conditions
        </a>{' '}
        |{' '}
        <a href='/#/privacy' target='_blank' rel='noreferrer'>
          Privacy Policy
        </a>{' '}
        | {footerYear} node-react-template <sup>TM</sup> version {version}
      </p>
    </div>
  )
}

export default Footer
