import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import { createBrowserHistory } from 'history'
import { ResponseData } from '../models/api/IResponse'

export const getAIConn = async (): Promise<string> => {
  try {
    const response = await fetch('/app/aiconn')
    const result = await response.json()
    return ResponseData<string>(result) || ''
  } catch {
    return ''
  }
}
const reactPlugin = new ReactPlugin()
let appInsights: ApplicationInsights
let tooManyRequests = false

getAIConn()
  .then((conn) => {
    console.log('** Starting Application Insights...')

    const browserHistory = createBrowserHistory()
    appInsights = new ApplicationInsights({
      config: {
        connectionString: conn,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: { history: browserHistory },
        },
      },
    })

    appInsights.loadAppInsights()
  })
  .catch((error: number) => {
    console.log(`** Couldn't start Application Insights. Error: ${error}`)
    tooManyRequests = error === 429
  })

export { reactPlugin, appInsights, tooManyRequests }
