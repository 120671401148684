if (!Object.entries) {
  Object.entries = (obj) => {
    let ownProps = Object.keys(obj)
    let length = ownProps.length
    let resArray = new Array(length)
    while (length--) resArray[length] = [ownProps[length], obj[ownProps[length]]]

    return resArray
  }
}

// Array.find polyfill
if (!Array.prototype.find) {
  // eslint-disable-next-line
  Object.defineProperty(Array.prototype, 'find', {
    value: function (predicate) {
      // 1. Let O be ? ToObject(this value).
      if (this == null) {
        throw TypeError('"this" is null or not defined')
      }

      var o = Object(this)

      // 2. Let len be ? ToLength(? Get(O, "length")).
      var len = o.length >>> 0

      // 3. If IsCallable(predicate) is false, throw a TypeError exception.
      if (typeof predicate !== 'function') {
        throw TypeError('predicate must be a function')
      }

      // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
      var thisArg = arguments[1]

      // 5. Let k be 0.
      var k = 0

      // 6. Repeat, while k < len
      while (k < len) {
        // a. Let Pk be ! ToString(k).
        // b. Let kValue be ? Get(O, Pk).
        // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
        // d. If testResult is true, return kValue.
        var kValue = o[k]
        if (predicate.call(thisArg, kValue, k, o)) {
          return kValue
        }
        // e. Increase k by 1.
        k++
      }

      // 7. Return undefined.
      return undefined
    },
    configurable: true,
    writable: true,
  })
}

// Array.findIndex polyfill
if (!Array.prototype.findIndex) {
  // eslint-disable-next-line
  Object.defineProperty(Array.prototype, 'findIndex', {
    value: function (predicate) {
      // 1. Let O be ? ToObject(this value).
      if (this == null) {
        throw new TypeError('"this" is null or not defined')
      }

      var o = Object(this)

      // 2. Let len be ? ToLength(? Get(O, "length")).
      var len = o.length >>> 0

      // 3. If IsCallable(predicate) is false, throw a TypeError exception.
      if (typeof predicate !== 'function') {
        throw new TypeError('predicate must be a function')
      }

      // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
      var thisArg = arguments[1]

      // 5. Let k be 0.
      var k = 0

      // 6. Repeat, while k < len
      while (k < len) {
        // a. Let Pk be ! ToString(k).
        // b. Let kValue be ? Get(O, Pk).
        // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
        // d. If testResult is true, return k.
        var kValue = o[k]
        if (predicate.call(thisArg, kValue, k, o)) {
          return k
        }
        // e. Increase k by 1.
        k++
      }

      // 7. Return -1.
      return -1
    },
    configurable: true,
    writable: true,
  })
}

// Number.isFinite polyfill
if (Number.isFinite === undefined)
  Number.isFinite = function (value) {
    return typeof value === 'number' && isFinite(value)
  }

// Array.fill polyfill
if (!Array.prototype.fill) {
  // eslint-disable-next-line
  Object.defineProperty(Array.prototype, 'fill', {
    value: function (value) {
      // Steps 1-2.
      if (this == null) {
        throw new TypeError('this is null or not defined')
      }

      var O = Object(this)

      // Steps 3-5.
      var len = O.length >>> 0

      // Steps 6-7.
      var start = arguments[1]
      var relativeStart = start >> 0

      // Step 8.
      var k = relativeStart < 0 ? Math.max(len + relativeStart, 0) : Math.min(relativeStart, len)

      // Steps 9-10.
      var end = arguments[2]
      var relativeEnd = end === undefined ? len : end >> 0

      // Step 11.
      var finalValue = relativeEnd < 0 ? Math.max(len + relativeEnd, 0) : Math.min(relativeEnd, len)

      // Step 12.
      while (k < finalValue) {
        O[k] = value
        k++
      }

      // Step 13.
      return O
    },
  })
}

// Array.prototype.keys polyfill
if (!Array.prototype.keys) {
  // eslint-disable-next-line
  Array.prototype.keys = function() {
    var k,
      a = [],
      nextIndex = 0,
      // eslint-disable-next-line
      ary = this
    k = ary.length
    while (k > 0) a[--k] = k
    a.next = function () {
      return nextIndex < ary.length ? { value: nextIndex++, done: false } : { done: true }
    }
    return a
  }
}

// Object.keys polyfill
if (!Object.keys) {
  Object.keys = (function () {
    var hasOwnProperty = Object.prototype.hasOwnProperty,
      // eslint-disable-next-line
      hasDontEnumBug = !{ toString: null }.propertyIsEnumerable('toString'),
      dontEnums = [
        'toString',
        'toLocaleString',
        'valueOf',
        'hasOwnProperty',
        'isPrototypeOf',
        'propertyIsEnumerable',
        'constructor',
      ],
      dontEnumsLength = dontEnums.length

    return function (obj) {
      if (typeof obj !== 'function' && (typeof obj !== 'object' || obj === null)) {
        throw new TypeError('Object.keys called on non-object')
      }

      var result = [],
        prop,
        i

      for (prop in obj) {
        if (hasOwnProperty.call(obj, prop)) {
          result.push(prop)
        }
      }

      if (hasDontEnumBug) {
        for (i = 0; i < dontEnumsLength; i++) {
          if (hasOwnProperty.call(obj, dontEnums[i])) {
            result.push(dontEnums[i])
          }
        }
      }
      return result
    }
  })()
}

// Object.entries polyfill
if (!Object.entries) {
  Object.entries = function (obj) {
    var ownProps = Object.keys(obj),
      i = ownProps.length,
      resArray = new Array(i) // preallocate the Array
    while (i--) resArray[i] = [ownProps[i], obj[ownProps[i]]]

    return resArray
  }
}

// Object.values polyfill
if (!Object.values) {
  Object.values = function (obj) {
    var ownProps = Object.keys(obj),
      i = ownProps.length,
      resArray = new Array(i) // preallocate the Array

    while (i--) resArray[i] = obj[ownProps[i]]

    return resArray
  }
}

// String.startsWith polyfill
if (!String.prototype.startsWith) {
  // eslint-disable-next-line
  Object.defineProperty(String.prototype, 'startsWith', {
    value: function (search, rawPos) {
      var pos = rawPos > 0 ? rawPos | 0 : 0
      return this.substring(pos, pos + search.length) === search
    },
  })
}

// Math.log2
if (!Math.log2) {
  Math.log2 = function (x) {
    return Math.log(x) * Math.LOG2E
  }
}
